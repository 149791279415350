import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import KubernetesContent from '../components/Kubernetes/KubernetesContent/KubernetesContent'
import KubernetesHero from '../components/Kubernetes/KubernetesHero/KubernetesHero'

const Kubernetes = ({ data }) => {
  const postNode = {
    title: `Kubernetes - ${config.siteTitle}`,
  }

  const solutions = data && data.allContentfulSolution.edges
  const filteredSolutions = solutions.filter(a => {
    console.log(a)
    if (a.node.slug.includes('training')) {
      return true
    } else {
      return false
    }
  })

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="kubernetes" customTitle />
      <Helmet>
        <title>{`Kubernetes - ${config.siteTitle}`}</title>
      </Helmet>
      <KubernetesHero />
      <KubernetesContent solutions={filteredSolutions} />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulSolution(limit: 100) {
      edges {
        node {
          title
          slug
          tags {
            title
            slug
          }
          hero {
            title
            subTitle
            callToAction
            callToActionLink
            background {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          metaDescription {
            internal {
              content
            }
          }
          body {
            content {
              nodeType
              content {
                value
              }
            }
          }
        }
      }
    }
  }
`

export default Kubernetes
